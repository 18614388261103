import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient } from "../../utils/restClient";

const initialState = {
  plays: [],
};

export default {
  namespaced: true,
  name: "cart",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_CART_PLAYS](state, payload = []) {
      state.plays = payload;
    },
  },
  actions: {
    async getCartPlays({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.cart.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_CART_PLAYS, response.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_CART_PLAYS, []);
          reject(error);
        }
      });
    },
    async deleteCartPlay(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.cart.single(payload),
            "DELETE"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchLotteries(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.lotteries.base,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    addToCart(_, payload = {}) {
      return restClient(path.client.cart.base, "POST", { plays: payload });
    },
  },
};
