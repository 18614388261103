var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.layout.breakPoint && !_vm.layout.collapsed)?_c('span',{staticClass:"backdrop",style:({
      position: 'fixed',
      float: 'left',
      zIndex: 999,
      height: '100vh',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      width: '100vw',
      backgroundColor: 'rgba(0,0,0,0.5)',
      paddingTop: 0,
      transition: 'all 0.3s ease-in-out',
    }),on:{"click":() => _vm.layout.breakPoint && _vm.onCollapse(true)}}):_vm._e(),_c('a-layout-sider',{staticClass:"sidebar-container",style:({
      position: 'fixed',
      float: 'left',
      zIndex: 999,
      height: '100vh',
      left: 0,
      paddingTop: 0,
    }),attrs:{"breakpoint":"lg","width":256,"collapsed":_vm.layout.collapsed,"trigger":null,"collapsible":true,"collapsed-width":_vm.layout.breakPoint ? '0px' : '88px'},on:{"breakpoint":_vm.onBreakPoint}},[_c('div',{staticClass:"sidebar-container-logo",class:{ mini: _vm.layout.collapsed }},[(_vm.layout.breakPoint)?_c('a-button',{staticClass:"btn-close",attrs:{"shape":"circle","icon":"close"},on:{"click":() => _vm.onCollapse(true)}}):_vm._e(),_c('router-link',{style:({ display: 'flex', justifyContent: 'center' }),attrs:{"to":{ name: 'home' }}},[_c('div',{staticClass:"sidebar-logo",class:{
            'sidebar-logo': !(_vm.layout.collapsed && !_vm.layout.breakPoint),
            'sidebar-logo-collapsed': _vm.layout.collapsed && !_vm.layout.breakPoint,
          },attrs:{"alt":"Logo"},on:{"click":() => _vm.layout.breakPoint && _vm.onCollapse(true)}})])],1),_c('perfect-scrollbar',[_c('div',{staticClass:"scroll-bar",style:({
          maxHeight: 'CALC(100vh - 88.94px)',
          paddingBottom: 25,
        })},[_c('a-menu',{style:({
            borderRight: 0,
            height: 'calc(100% - 16px)',
            background: 'transparent',
          }),attrs:{"theme":"dark","mode":"inline","openKeys":_vm.layout.openKeys.filter((key) => !!key) || [],"selectedKeys":_vm.layout.selectedKeys || [],"subMenuCloseDelay":0.1,"subMenuOpenDelay":0.1},on:{"openChange":_vm.onOpenChange,"select":_vm.onSelectMenuChange}},[_c('a-divider',{style:({
              margin: '10px 0',
              marginBottom: '15px',
            })}),_vm._l((_vm.menus),function(menu){return [(menu.children && menu.children.length)?_c('a-sub-menu',{key:menu.key},[_c('span',{attrs:{"slot":"title"},slot:"title"},[(menu.icon)?_c(menu.isFontAwesome ? 'font-awesome-icon' : 'a-icon',{tag:"component",class:menu.isFontAwesome && 'anticon',attrs:{"icon":menu.icon,"type":menu.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(menu.title))])],1),_vm._l((menu.children),function(submenu){return _c('a-menu-item',{key:submenu.key,on:{"click":() => _vm.layout.breakPoint && _vm.onCollapse(true)}},[(submenu.icon)?_c(submenu.isFontAwesome ? 'font-awesome-icon' : 'a-icon',{tag:"component",class:submenu.isFontAwesome && 'anticon',attrs:{"icon":submenu.icon,"type":submenu.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(submenu.title))]),(submenu.key)?_c('router-link',{attrs:{"to":{ name: submenu.key }}}):_vm._e()],1)})],2):_c('a-menu-item',{key:menu.key,on:{"click":() => _vm.layout.breakPoint && _vm.onCollapse(true)}},[(menu.icon)?_c(menu.isFontAwesome ? 'font-awesome-icon' : 'a-icon',{tag:"component",class:`${menu.isFontAwesome && 'anticon'} ${
                  menu.key === 'how-to-play' ? 'rotate' : ''
                }`,attrs:{"icon":menu.icon,"type":menu.icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(menu.title))]),(menu.key)?_c('router-link',{attrs:{"to":{ name: menu.key }}}):_vm._e()],1)]})],2)],1)]),(!(_vm.layout.collapsed && _vm.isMobile))?_c('div',{staticClass:"mode-container",class:{ collapsed: _vm.layout.collapsed && !_vm.layout.breakPoint }},[_c('a-divider',{staticClass:"custom-separator"}),_c('div',{staticClass:"mode-content",staticStyle:{"transition":"'all 0.3s ease-in-out'"}},[(!(_vm.layout.collapsed && !_vm.layout.breakPoint))?_c('label',{staticStyle:{"transition":"'all 0.3s ease-in-out'"}},[_c('font-awesome-icon',{staticClass:"anticon",class:_vm.layout.darkMode ? 'dark-mode' : 'light-mode',attrs:{"icon":['fas', _vm.layout.darkMode ? 'sun' : 'moon']}}),(_vm.layout.darkMode)?_c('span',{staticClass:"slower",staticStyle:{"transition":"'all 0.3s ease-in-out'"}},[_vm._v("Modo claro")]):_c('span',{staticClass:"slower",staticStyle:{"transition":"'all 0.3s ease-in-out'"}},[_vm._v("Modo oscuro")])],1):_vm._e(),_c('a-switch',{staticClass:"custom-switch",attrs:{"loading":_vm.submiting,"disabled":_vm.submiting,"checked":_vm.layout.darkMode},on:{"change":_vm.onChangeMode,"click":() => _vm.layout.breakPoint && _vm.onCollapse(true)}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }