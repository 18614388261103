import _ from "lodash";
import { path } from "../../api";
import { restClient, restClientAll } from "../../utils/restClient";
import router from "../../router";
import labels from "@/utils/labels";

const initialState = {};

export default {
  namespaced: true,
  name: "shortUrl",
  state: _.cloneDeep(initialState),
  actions: {
    async getShortUrl(_, payload = null) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientAll(
            path.client.shortUrl.single(payload),
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async generateShortUrl(_, payload = null) {
      const paymentMethod = payload.paymentMethodSelected;

      let pathLink = "";
      switch (paymentMethod) {
        case labels.users.table.columns.action.paymentClient.SKYCHAIN:
          pathLink = path.admin.transactions.skyChain;
          break;
        case labels.users.table.columns.action.paymentClient.MONEYGRATOR:
          pathLink = path.admin.transactions.moneygrator;
          break;
        case labels.users.table.columns.action.paymentClient.CHARGEMONEY:
          pathLink = path.admin.transactions.chargemoney;
          break;
        case labels.users.table.columns.action.paymentClient.PAYWALL:
          pathLink = path.admin.transactions.paywallLink;
          break;
        default:
          pathLink = path.admin.transactions.moneygrator;
          break;
      }

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(pathLink, "POST", payload);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchShortUrl(_, payload) {
      try {
        const { data: resData } = await restClientAll(
          path.client.shortUrl.single(payload),
          "GET"
        );
        const data = JSON.parse(window.atob(resData.url));

        // redirect to register with referral code
        if (data.shared_by) {
          router.push({
            name: "register",
            query: { r: data.shared_by },
          });
        } else {
          router.push({
            name: "register",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
