import Vue from "vue";
import accounting from "accounting";

Vue.filter(
  "formatMoney",
  function (value, symbol, precision, thousand, decimal) {
    if (isNaN(value)) {
      return "-";
    }
    return accounting.formatMoney(value, symbol, precision, thousand, decimal);
  }
);

Vue.filter("formatNumber", function (value, precision, thousand, decimal) {
  if (isNaN(value)) {
    return "-";
  }
  return accounting.formatNumber(value, precision, thousand, decimal);
});

Vue.filter("ucfirst", function (value = "") {
  if (value.trim() === "") return "";
  // ucfirst
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("ucword", function (value = "") {
  if (value.trim() === "") return "";
  // ucword
  return value.replace(/\b\w/g, function (l) {
    return l.toUpperCase();
  });
});
