import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient } from "../../utils/restClient";

const initialState = {
  roles: [],
  admins: [],
  admin: null,
};

export default {
  namespaced: true,
  name: "admins",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_ADMIN](state, payload = null) {
      state.admin = payload;
    },
    [actions.GET_ROLES](state, payload = []) {
      state.roles = payload;
    },
    [actions.GET_ADMINS](state, payload = []) {
      state.admins = payload;
    },
    [actions.CREATE_ADMIN](state, payload = null) {
      state.admin = payload;
    },
    [actions.UPDATE_ADMIN](state, payload = null) {
      state.admin = payload;
    },
    [actions.DELETE_ADMIN](state, payload = null) {
      state.admin = payload;
    },
    [actions.RESET_ADMIN_FORM](state) {
      state.admin = null;
    },
  },
  actions: {
    async fetchAdmins({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.admins.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_ADMINS, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_ADMINS, []);
          reject(error);
        }
      });
    },
    async fetchRoles({ commit }, payload = {}) {
      try {
        const {
          data: { data: roles = [] },
        } = await restClient(path.admin.admins.roles, "GET", null, {
          ...payload,
          paginate: false,
        });
        commit(actions.GET_ROLES, roles);
      } catch (error) {
        commit(actions.GET_ROLES, []);
      }
    },
    async fetchAdmin({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.admins.single(payload),
            "GET"
          );
          commit(actions.GET_ADMIN, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_ADMIN);
          reject(error);
        }
      });
    },
    async createAdmin({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.admins.base,
            "POST",
            payload
          );
          commit(actions.CREATE_ADMIN, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async updateAdmin({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.admins.single(payload.id),
            "PUT",
            payload
          );
          commit(actions.UPDATE_ADMIN, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async deleteAdmin({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.admins.single(payload),
            "DELETE"
          );
          commit(actions.DELETE_ADMIN);
          resolve(response);
        } catch (error) {
          commit(actions.DELETE_ADMIN);
          reject(error);
        }
      });
    },
    resetForm({ commit }) {
      commit(actions.RESET_ADMIN_FORM);
    },
  },
  getters: {
    getRoles(state) {
      return state.roles;
    },
  },
};
