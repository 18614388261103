<template>
  <a-layout-header
    style="
      background: #fff;
      padding: 0;
      width: 100%;
      height: 60px;
      padding: 0 5px;
      padding-right: 5px;
      z-index: 1;
      line-height: 60px;
    "
  >
    <a-icon
      class="trigger"
      :type="layout.collapsed ? 'menu-unfold' : 'menu-fold'"
      @click="handleUpdateLayout"
    />

    <a-popover v-model="visible" trigger="click" placement="bottomRight">
      <div
        v-if="loggedUser"
        :style="{
          float: 'right',
          margin: '0 auto',
          padding: '0 20px',
          userSelect: 'none',
        }"
        class="hover user-info"
      >
        <a-avatar
          v-if="loggedUser.name"
          :style="{ backgroundColor: '#0ca750', color: '#FFF' }"
          >{{ getInitials(loggedUser.name) }}</a-avatar
        >
        <a-avatar
          v-else
          :style="{ backgroundColor: '#0ca750', color: '#FFF' }"
          icon="user"
        />
        <span class="user-name">{{ loggedUser.name }}</span>
      </div>
      <template slot="content">
        <a-menu
          :style="{ width: 'auto', borderRight: 'none' }"
          mode="vertical"
          theme="light"
          class="actions-menu"
          :selectedKeys="[]"
          @click="handleActionClick"
        >
          <!-- <a-menu-item key="profile">
            <a-icon type="user" />
            <span>{{ labels.header.adminMenu.profile }}</span>
          </a-menu-item> -->
          <a-menu-item key="change-password">
            <a-icon type="lock" />
            <span>{{ labels.header.adminMenu.changePassword }}</span>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="logout">
            <a-icon type="logout" />
            <span>{{ labels.header.adminMenu.logout }}</span>
          </a-menu-item>
        </a-menu>
      </template>
    </a-popover>

    <AdminChangePasswordFormModal
      :visible="showModal"
      @close="showModal = false"
    />
  </a-layout-header>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { stringInitials } from "@/utils/utils";
import labels from "@/utils/labels";
import AdminChangePasswordFormModal from "../../AdminChangePasswordFormModal.vue";
export default {
  name: "AdminHeader",
  components: {
    AdminChangePasswordFormModal,
  },
  data() {
    return {
      visible: false,
      showModal: false,
      labels: labels.layout,
    };
  },
  methods: {
    ...mapActions("layout", ["updateLayout"]),
    ...mapActions("adminAuth", ["handleLogout"]),
    handleUpdateLayout() {
      this.updateLayout({
        ...this.layout,
        collapsed: !this.layout.collapsed,
      });
    },
    async handleActionClick({ key }) {
      switch (key) {
        case "profile":
          console.log("/admin/profile");
          break;
        case "change-password":
          this.showModal = true;
          break;
        case "logout":
          await this.handleLogout();
          this.$router.push({ name: "admin" });
          break;
      }
      this.visible = false;
    },
    getInitials($string) {
      return stringInitials($string);
    },
  },
  computed: {
    ...mapGetters("layout", ["getLayoutDataStore"]),
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    layout() {
      return this.getLayoutDataStore;
    },
    loggedUser() {
      return this.getLoggedUser;
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
#admin-layout {
  .ant-avatar {
    margin-right: 7px;
  }
  @media screen and (max-width: $screen-mobile) {
    .user {
      &-info {
        padding: 0 10px;
        .ant-avatar {
          margin-right: 0;
        }
      }
      &-name {
        display: none;
        transition: 0.5s;
      }
    }
  }
}
</style>
