import _ from "lodash";
import { path } from "../../api";
import { restClient } from "../../utils/restClient";
import * as actions from "../../utils/actions";

const initialState = {
  orders: [],
  order: null,
};

export default {
  namespaced: true,
  name: "orders",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_ORDERS](state, payload = null) {
      state.orders = payload;
    },
    [actions.GET_ORDER](state, payload = null) {
      state.order = payload;
    },
  },
  actions: {
    async fetchOrders({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.orders.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_ORDERS, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_ORDERS, []);
          reject(error);
        }
      });
    },
    async fetchUserCashBalanceReport(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.orders.userCashBalanceReport,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchOrder({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.orders.single(payload),
            "GET"
          );
          commit(actions.GET_ORDER, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_ORDER);
          reject(error);
        }
      });
    },
    async fetchWonOrders({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.orders.base,
            "GET",
            null,
            { ...payload, wonTickets: true }
          );
          commit(actions.GET_ORDERS, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_ORDERS, []);
          reject(error);
        }
      });
    },
    cancelOrder({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.orders.single(payload),
            "DELETE"
          );
          commit(actions.GET_ORDER, response.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_ORDER);
          reject(error);
        }
      });
    },
    payTicket(_, payload = {}) {
      return restClient(path.client.orders.base, "POST", payload);
    },
    fetchTickets(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.orders.base,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchTicket(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.orders.single(payload),
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    cancelTicket(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.orders.single(payload),
            "DELETE"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    shareTicket(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.orders.share(payload),
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
