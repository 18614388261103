import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient } from "../../utils/restClient";

const initialState = {
  commissionProfiles: [],
  commissionProfile: null,
};

export default {
  namespaced: true,
  name: "commissionProfiles",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_COMMISSION_PROFILES](state, payload = []) {
      state.commissionProfile = payload;
    },
    [actions.GET_COMMISSION_PROFILE](state, payload = null) {
      state.commissionProfile = payload;
    },
    [actions.CREATE_COMMISSION_PROFILE](state, payload = null) {
      state.commissionProfile = payload;
    },
    [actions.UPDATE_COMMISSION_PROFILE](state, payload = null) {
      state.commissionProfile = payload;
    },
    [actions.RESET_COMMISSION_PROFILE_FORM](state) {
      state.commissionProfile = null;
    },
  },
  actions: {
    async fetchCommissionProfiles({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.commissionProfiles.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_COMMISSION_PROFILES, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_COMMISSION_PROFILES, []);
          reject(error);
        }
      });
    },
    async fetchCommissionProfile({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.commissionProfiles.single(payload),
            "GET"
          );
          commit(actions.GET_COMMISSION_PROFILE, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_COMMISSION_PROFILE, null);
          reject(error);
        }
      });
    },
    async createCommissionProfile({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.commissionProfiles.base,
            "POST",
            payload
          );
          commit(actions.CREATE_COMMISSION_PROFILE, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async updateCommissionProfile({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.commissionProfiles.single(payload.id),
            "PUT",
            payload
          );
          commit(actions.UPDATE_COMMISSION_PROFILE, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async deleteCommissionProfile(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.commissionProfiles.single(payload),
            "DELETE"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    resetForm({ commit }) {
      commit(actions.RESET_COMMISSION_PROFILE_FORM);
    },
  },
};
