import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient } from "../../utils/restClient";

const initialState = {
  depositAccountGroups: [],
  depositAccountGroup: null,
};

export default {
  namespaced: true,
  name: "depositAccountGroups",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_DEPOSIT_ACCOUNT_GROUPS](state, payload = []) {
      state.depositAccountGroups = payload;
    },
    [actions.GET_DEPOSIT_ACCOUNT_GROUP](state, payload = null) {
      state.depositAccountGroup = payload;
    },
    [actions.CREATE_DEPOSIT_ACCOUNT_GROUP](state, payload = null) {
      state.depositAccountGroup = payload;
    },
    [actions.UPDATE_DEPOSIT_ACCOUNT_GROUP](state, payload = null) {
      state.depositAccountGroup = payload;
    },
    [actions.RESET_DEPOSIT_ACCOUNT_GROUP_FORM](state) {
      state.depositAccountGroup = null;
    },
  },
  actions: {
    async fetchDepositAccountGroups({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.depositAccountGroups.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_DEPOSIT_ACCOUNT_GROUPS, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_DEPOSIT_ACCOUNT_GROUPS, []);
          reject(error);
        }
      });
    },
    async fetchDepositAccountGroup({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.depositAccountGroups.single(payload),
            "GET"
          );
          commit(actions.GET_DEPOSIT_ACCOUNT_GROUP, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_DEPOSIT_ACCOUNT_GROUP, null);
          reject(error);
        }
      });
    },
    async createDepositAccountGroup({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.depositAccountGroups.base,
            "POST",
            payload
          );
          commit(actions.CREATE_DEPOSIT_ACCOUNT_GROUP, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async updateDepositAccountGroup({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.depositAccountGroups.single(payload.id),
            "PUT",
            payload
          );
          commit(actions.UPDATE_DEPOSIT_ACCOUNT_GROUP, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async deleteDepositAccountGroup(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.depositAccountGroups.single(payload),
            "DELETE"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    resetForm({ commit }) {
      commit(actions.RESET_DEPOSIT_ACCOUNT_GROUP_FORM);
    },
  },
};
