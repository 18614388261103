import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient } from "../../utils/restClient";

const initialState = {
  transactions: [],
  transaction: null,
};

export default {
  namespaced: true,
  name: "transactions",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_TRANSACTIONS](state, payload = []) {
      state.transactions = payload;
    },
    [actions.GET_TRANSACTION](state, payload = null) {
      state.transaction = payload;
    },
    [actions.PROCESS_TRANSACTION](state, payload = null) {
      state.transaction = payload;
    },
    [actions.CANCEL_TRANSACTION](state, payload = null) {
      state.transaction = payload;
    },
    [actions.APPROVE_TRANSACTION](state, payload = null) {
      state.transaction = payload;
    },
    [actions.GENERATE_UD_COMMISSION_TRANSACTION](state, payload = null) {
      state.transaction = payload;
    },
    [actions.REJECT_TRANSACTION](state, payload = null) {
      state.transaction = payload;
    },
    [actions.RESET_TRANSACTION_FORM](state) {
      state.transaction = null;
    },
    [actions.REQUEST_CANCEL_WITHDRAW](state, payload = null) {
      state.transaction = payload;
    },
  },
  actions: {
    async fetchTransactions({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.transactions.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_TRANSACTIONS, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_TRANSACTIONS, []);
          reject(error);
        }
      });
    },
    async fetchClientTransactions(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.transactions.base,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchTransactionTypes(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.transactions.types,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchTransactionOrigins(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.transactions.origins,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchTransaction({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.transactions.single(payload),
            "GET"
          );
          commit(actions.GET_TRANSACTION, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_TRANSACTION, null);
          reject(error);
        }
      });
    },
    async fetchClientTransaction(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.transactions.single(payload),
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async processTransaction({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.transactions.base,
            "POST",
            payload
          );
          commit(actions.PROCESS_TRANSACTION, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getSkyChainPaymentLink(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.transactions.skyChain,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getMoneygratorPaymentLink(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.transactions.moneygrator,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getChargemoneyPaymentLink(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.transactions.chargemoney,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getPaywallPaymentLink(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.transactions.paywall,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async cancelTransaction({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.transactions.single(payload),
            "PUT"
          );
          commit(actions.CANCEL_TRANSACTION, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async requestTransaction(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.transactions.base,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async approveTransaction({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { id, formData } = payload;
          const response = await restClient(
            path.admin.transactions.approve(id),
            "POST",
            formData,
            {},
            { "Content-Type": "multipart/form-data" }
          );
          commit(actions.APPROVE_TRANSACTION, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async generateCommission({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.transactions.generateUdCommission(payload),
            "PUT"
          );
          commit(actions.GENERATE_UD_COMMISSION_TRANSACTION, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async rejectTransaction({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { id, ...data } = payload;
          const response = await restClient(
            path.admin.transactions.reject(id),
            "PUT",
            data
          );
          commit(actions.REJECT_TRANSACTION, response?.data);
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    async cancelTransfer(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.transactions.single(payload),
            "PUT"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async generateTransactionToken() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.transactions.token,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async requestCancelWithdraw({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.transactions.requestCancelWithdraw(payload),
            "PUT"
          );
          commit(actions.REQUEST_CANCEL_WITHDRAW, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    resetForm({ commit }) {
      commit(actions.RESET_TRANSACTION_FORM);
    },
  },
};
