import _ from "lodash";
import { path } from "../../api";
import { restClient } from "../../utils/restClient";

const initialState = {
  commissions: [],
  commission: null,
};

export default {
  namespaced: true,
  name: "blocks",
  state: _.cloneDeep(initialState),
  mutations: {},
  actions: {
    async generateReport(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.commissions.report,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async paidCommissions(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.commissions.paid,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
