<template>
  <a-modal
    class="change-password-form-modal"
    :visible="visible"
    :confirm-loading="submitting"
    :footer="null"
    @cancel="handleClose"
  >
    <template #title>
      <h2 class="title">{{ labels.title }}</h2>
    </template>
    <ValidationObserver ref="observer">
      <a-form
        :label-width="80"
        slot-scope="{ validate }"
        @submit.prevent="validate().then(handleSubmit)"
      >
        <Input
          v-model="form.current_password"
          type="password"
          rules="required"
          vid="current-password"
          :placeholder="labels.form.current_password.placeholder"
          :allowClear="true"
          :label="labels.form.current_password.label"
        />
        <Input
          v-model="form.password"
          ref="password"
          type="password"
          rules="required|min:6|validatePassword"
          vid="password"
          :placeholder="labels.form.password.placeholder"
          :allowClear="true"
          :label="labels.form.password.label"
        />
        <Input
          v-model="form.password_confirmation"
          type="password"
          rules="required|confirmed:password"
          vid="password_confirmation"
          :placeholder="labels.form.password_confirmation.placeholder"
          :allowClear="true"
          :label="labels.form.password_confirmation.label"
        />
        <a-form-item style="text-align: right">
          <a-button
            type="primary"
            html-type="submit"
            :loading="submitting"
            :disabled="submitting"
            class="btn-reset"
            @click.prevent="validate().then(handleSubmit)"
            block
          >
            <span>{{ labels.form.submit }}</span>
          </a-button>
        </a-form-item>
        <a-form-item style="text-align: center">
          <a-button
            type="danger"
            class="back-link"
            @click.prevent="handleClose"
            block
            ghost
          >
            {{ labels.form.cancel }}
          </a-button>
        </a-form-item>
      </a-form>
    </ValidationObserver>
  </a-modal>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import Input from "@/components/core/VeeValidateForm/Input";
import labels from "@/utils/labels";
import _ from "lodash";
import { mapActions } from "vuex";
const initialState = {
  current_password: "",
  password: "",
  password_confirmation: "",
};
export default {
  name: "ChangePasswordFormModal",
  components: {
    ValidationObserver,
    Input,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    return {
      form: _.cloneDeep(initialState),
      submitting: false,
      labels: labels.changePasswordForm,
    };
  },
  methods: {
    ...mapActions("adminAuth", ["handleChangePassword"]),
    async handleSubmit() {
      try {
        await this.handleChangePassword(this.form);
        this.$success({
          title: this.labels.form.success,
          centered: true,
          onOk: () => {
            this.form = _.cloneDeep(initialState);
            this.$refs.observer.reset();
            this.handleClose();
          },
        });
      } catch (error) {
        if (error.isAxiosError && [401, 403].includes(error.response.status)) {
          this.$router.push({ name: "admin" });
        } else if (error.isAxiosError && error.response.status === 400) {
          this.$notification["error"]({
            message: error.response?.data?.message || labels.formError,
            description: (h) => {
              return h(
                "ul",
                error.response?.data?.additional_errors?.map((error) => {
                  return h("li", error);
                })
              );
            },
          });
        } else {
          this.$notification["error"]({
            message:
              error?.response?.data?.message ||
              error.message ||
              labels.unknownServerError,
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.change-password-form-modal {
  padding: 3.125rem;
  max-width: 481px;
  @media screen and (max-width: $screen-mobile) {
    padding: 2.125rem 1rem;
  }
  .title {
    font-family: "Exo 2", sans-serif;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    word-break: break-word;
    font-size: 1.5rem;
    margin-bottom: 0;
    text-align: center;
  }
}
</style>
