<template>
  <ValidationProvider :vid="vid" :ref="vid" :name="$attrs.label" :rules="rules">
    <a-form-item
      v-bind="$attrs.formItem || {}"
      slot-scope="{ errors, flags }"
      :label="showLabel ? $attrs.label : ''"
      :align="$attrs.align || 'left'"
      :validateStatus="resolveState({ errors, flags })"
      :help="errors[0]"
      :htmlFor="$attrs.htmlFor"
      :labelCol="$attrs.labelCol"
    >
      <component
        :is="component"
        v-bind="$attrs"
        :style="{ width: '100% !important', ...($attrs.customStyle || {}) }"
        v-model="innerValue"
        @blur="$emit('blur', $event)"
        @change="$emit('change', $event)"
        @focus="$emit('focus', $event)"
        @pressEnter.prevent="$emit('pressEnter', $event)"
        v-mask="isText ? mask : false"
      >
        <a-icon
          v-if="$attrs.icon"
          slot="prefix"
          :type="$attrs.icon"
          :style="$attrs.iconStyle || {}"
        />
      </component>
      <slot name="append" />
    </a-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider,
  },
  emits: ["blur", "change", "focus", "input"],
  props: {
    vid: {
      type: String,
    },
    rules: {
      type: [Object, String, Array],
      default: "",
    },
    // must be included in props
    value: {
      type: null,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    transform: {
      type: Function,
      default: (value) => value,
    },
    mask: {
      type: [String, Boolean],
      default: false,
    },
  },
  data: () => ({
    innerValue: "",
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", this.transform ? this.transform(newVal) : newVal);
    },
    // Handles external model changes.
    value: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.innerValue = this.transform ? this.transform(newVal) : newVal;
        }
      },
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
  methods: {
    resolveState({ errors, flags }) {
      if (errors[0]) {
        return "error";
      }

      if (flags.pending) {
        return "validating";
      }

      if (flags.valid) {
        return "success";
      }

      return "";
    },
  },
  computed: {
    component() {
      switch (this.$attrs.type) {
        case "password":
          return "a-input-password";
        case "number":
          return "a-input-number";
        default:
          return "a-input";
      }
    },
    isText() {
      return this.$attrs.type === "text" || !this.$attrs.type;
    },
  },
};
</script>
