<template>
  <a-layout-header class="client-header">
    <a-icon
      class="trigger"
      :type="layout.collapsed ? 'menu-unfold' : 'menu-fold'"
      @click="() => onCollapse(!!!layout.collapsed)"
    />
    <div class="action-menu">
      <template v-if="!loggedUser && !breakPoint">
        <a-button
          v-for="{ key, title } in menus.filter(({ key }) =>
            ['login', 'register'].includes(key)
          )"
          :type="key === 'register' ? 'link' : 'default'"
          :class="`btn-${key}`"
          :key="key"
          @click="goToRoute(key)"
        >
          <span>{{ title }}</span>
        </a-button>
      </template>
      <template v-if="loggedUser">
        <span class="btn-transaction-group">
          <router-link
            :to="{ name: 'balance-movements' }"
            :title="labels.header.balanceMovements"
          >
            <span class="btn-balance" :class="{ mini: breakPoint }">
              <span
                >{{ breakPoint ? "$" : labels.header.balance }}
                {{
                  (loggedUser.balance || 0) | formatMoney("", 2, ",", ".")
                }}</span
              >
              <a-button
                v-if="breakPoint"
                type="link"
                class="refresh-balance-link"
                @click.prevent="handleRefreshBalance"
                :disabled="syncing"
              >
                <a-icon type="sync" :spin="syncing" />
              </a-button>
            </span>
          </router-link>
          <a-button
            v-if="!breakPoint"
            type="primary"
            class="refresh-balance"
            @click="handleRefreshBalance"
            :disabled="syncing"
          >
            <a-icon type="sync" :spin="syncing" />
          </a-button>
          <a-button-group
            v-if="!breakPoint"
            v-bind="breakPoint && { size: 'small' }"
          >
            <a-button class="btn-deposit" @click="goToLink('DEPOSIT')">{{
              labels.header.deposit
            }}</a-button>
            <a-button
              v-if="!loggedUser.dww"
              class="btn-withdraw"
              @click="goToLink('WITHDRAW')"
              >{{ labels.header.withdraw }}</a-button
            >
          </a-button-group>
          <router-link
            v-if="false"
            :to="{ name: 'balance-movements' }"
            :title="labels.header.balanceMovements"
          >
            <span class="btn-balance" :class="{ mini: breakPoint }">
              <span
                >{{ breakPoint ? "$" : labels.header.balance }}
                {{
                  (loggedUser.balance || 0) | formatMoney("", 2, ",", ".")
                }}</span
              >
            </span>
          </router-link>
        </span>
        <a-dropdown
          v-if="breakPoint"
          placement="bottomCenter"
          class="transaction-dropdown"
        >
          <a-menu class="dropdown-menu" slot="overlay">
            <a-menu-item key="deposit" @click="goToLink('DEPOSIT')">{{
              labels.header.deposit
            }}</a-menu-item>
            <a-menu-item
              v-if="!loggedUser.dww"
              key="withdraw"
              @click="goToLink('WITHDRAW')"
              >{{ labels.header.withdraw }}</a-menu-item
            >
          </a-menu>
          <a-button class="dropdown-btn">
            <font-awesome-icon
              :icon="['fas', 'arrow-right-arrow-left']"
              class="anticon"
            />
          </a-button>
        </a-dropdown>
        <a-button
          :type="'link'"
          class="btn-cart"
          :class="{ mini: breakPoint }"
          @click="goToRoute('buy')"
        >
          <a-badge :count="plays.length || 0" title="Custom hover text">
            <a-icon type="shopping-cart" />
          </a-badge>
          <span v-if="!breakPoint" class="pl-24">{{
            labels.header.playNow
          }}</span>
        </a-button>
      </template>
      <a-popover
        v-if="loggedUser"
        v-model="visible"
        trigger="click"
        placement="bottomRight"
      >
        <div
          :style="{
            float: 'right',
            margin: '0 auto',
            userSelect: 'none',
          }"
          class="hover user-info"
        >
          <a-avatar
            v-if="fullName"
            :size="breakPoint ? 34 : 38"
            :style="{ backgroundColor: '#0ca750', color: '#FFF' }"
            >{{ getInitials(fullName) }}</a-avatar
          >
          <a-avatar
            v-else
            :style="{ backgroundColor: '#0ca750', color: '#FFF' }"
            icon="user"
          />
        </div>
        <template slot="title">
          <div class="content-user">
            <span class="content-user-name">{{ fullName }}</span>
            <span class="content-user-email">{{ loggedUser.email }}</span>
          </div>
        </template>
        <template slot="content">
          <a-menu
            :style="{ width: 'auto', borderRight: 'none' }"
            mode="vertical"
            theme="light"
            class="actions-menu"
            :selectedKeys="[]"
            @click="handleActionClick"
          >
            <a-menu-item key="ticket-list">
              <font-awesome-icon class="anticon" :icon="['fas', 'list']" />
              <span>{{ labels.header.userMenu.ticketList }}</span>
            </a-menu-item>
            <a-menu-item key="transaction-list">
              <font-awesome-icon
                class="anticon"
                :icon="['fas', 'money-bill-transfer']"
              />
              <span>{{ labels.header.userMenu.transferList }}</span>
            </a-menu-item>
            <a-menu-item key="balance-movements">
              <font-awesome-icon
                class="anticon"
                :icon="['fas', 'money-bill-wave']"
              />
              <span>{{ labels.header.userMenu.balanceMovements }}</span>
            </a-menu-item>
            <a-menu-item v-if="loggedUser.ecb" key="cash-balance-report">
              <font-awesome-icon
                class="anticon"
                :icon="['fas', 'file-invoice-dollar']"
              />
              <span>{{ labels.header.userMenu.cashBalanceReport }}</span>
            </a-menu-item>
            <a-menu-item key="referrals">
              <font-awesome-icon
                class="anticon"
                :icon="['fas', 'users-rays']"
              />
              <span>{{ labels.header.userMenu.referral }}</span>
            </a-menu-item>
            <a-menu-item key="notifications">
              <font-awesome-icon class="anticon" :icon="['fas', 'envelope']" />
              <span>{{ labels.header.userMenu.notifications }}</span>
            </a-menu-item>
            <a-menu-item key="promotion">
              <font-awesome-icon class="anticon" :icon="['fas', 'tag']" />
              <span>{{ labels.header.userMenu.promotion }}</span>
            </a-menu-item>
            <a-divider :style="{ margin: '6px 0' }" />
            <a-menu-item key="change-password">
              <a-icon type="lock" />
              <span>{{ labels.header.userMenu.changePassword }}</span>
            </a-menu-item>
            <a-menu-item key="logout">
              <a-icon type="logout" />
              <span>{{ labels.header.userMenu.logout }}</span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-popover>
      <a-popover
        v-if="loggedUser"
        v-model="showNotifications"
        trigger="click"
        placement="bottomRight"
        class="user-notifications"
      >
        <div
          :style="{
            float: 'right',
            margin: '0 auto',
            padding: '0 10px',
            userSelect: 'none',
          }"
          class="hover user-notifications-avatar"
        >
          <a-avatar
            :size="breakPoint ? 34 : 38"
            :style="{ backgroundColor: '#0ca750', color: '#FFF' }"
            icon="bell"
          />
          <div
            v-if="!!newNotifications.length"
            class="user-notifications-avatar-count"
          >
            <span class="user-notifications-avatar-count-text">{{
              notificationCount
            }}</span>
          </div>
        </div>
        <template slot="title">
          <div class="user-notifications-title">
            {{ labels.header.notifications.title }}
          </div>
        </template>
        <template slot="content">
          <a-menu
            v-if="getUserNotifications.length"
            :style="{ width: 'auto', borderRight: 'none' }"
            mode="vertical"
            theme="light"
            class="actions-menu user-notifications-list"
            style="max-height: 300px; overflow-y: auto"
            @click="handleNotificationsClick"
          >
            <a-menu-item
              v-for="notification in getUserNotifications"
              :key="notification.uuid"
            >
              <font-awesome-icon
                class="anticon"
                :icon="[
                  'fas',
                  notification.read ? 'envelope-open' : 'envelope',
                ]"
              />
              <span>{{ notification?.body || " - " }}</span>
              <a-tag
                class="user-notifications-list-new"
                :class="{
                  'user-notifications-list-new-animate': notification.new,
                }"
              >
                {{ labels.header.notifications.new }}
              </a-tag>
            </a-menu-item>
            <a-divider :style="{ margin: '6px 0' }" />
            <a-menu-item key="">
              <font-awesome-icon class="anticon" :icon="['fas', 'list']" />
              <span>{{ labels.header.notifications.all }}</span>
            </a-menu-item>
          </a-menu>

          <div v-else>
            <span class="title-subtext">{{
              labels.header.notifications.emptyText
            }}</span>
          </div>
        </template>
      </a-popover>

      <router-link
        v-if="!loggedUser && breakPoint"
        :to="{ name: 'login' }"
        class="ant-btn ant-btn-default btn-play-now"
        >{{ labels.header.playNow }}</router-link
      >
    </div>
  </a-layout-header>
</template>
<script>
import { menus } from "@/router/client-menus.js";
import { stringInitials } from "@/utils/utils";
import { mapActions, mapGetters, mapState } from "vuex";
import labels from "@/utils/labels";
import banner from "@/assets/images/admin/banner.png";
import { updateUserNotification } from "@/notifications/fcm-messaging";
import _ from "lodash";
export default {
  name: "AppHeader",
  data() {
    return {
      visible: false,
      showNotifications: false,
      labels: { ...labels.layout, confirmPromotion: labels.confirmPromotion },
      banner,
      syncing: false,
      submitting: false,
      promotionCode: "",
    };
  },
  created() {
    this.updateLayout({
      ...this.layout,
      selectedKeys: [this.$route.name],
    });
  },
  methods: {
    ...mapActions("layout", ["updateLayout"]),
    ...mapActions("clientAuth", [
      "handleLogout",
      "refreshBalance",
      "applyPromotion",
    ]),
    getInitials(name) {
      return stringInitials(name);
    },
    async handleActionClick({ key }) {
      switch (key) {
        case "referrals":
          this.goToRoute("referrals");
          break;
        case "ticket-list":
          this.goToRoute("ticket-list");
          break;
        case "change-password":
          this.goToRoute("change-password");
          break;
        case "transaction-list":
          this.goToRoute("transaction-list");
          break;
        case "balance-movements":
          this.goToRoute("balance-movements");
          break;
        case "cash-balance-report":
          if (this.loggedUser.ecb) this.goToRoute("cash-balance-report");
          break;
        case "notifications":
          this.goToRoute("notifications");
          break;
        case "promotion":
          this.openPromotionModal();
          break;
        case "logout":
          try {
            // TODO: Unsubscribe from userChannel
            await this.handleLogout();
          } catch (error) {
            console.error(error);
          } finally {
            this.goToRoute("login");
          }
          break;
      }
      this.visible = false;
    },
    async handleNotificationsClick({ key }) {
      this.showNotifications = false;
      this.$router.push({ name: "notifications", params: { uuid: key } });
    },
    onCollapse(collapsed = true) {
      this.updateLayout({
        ...this.layout,
        collapsed: collapsed,
      });
    },
    onSelectMenuChange({ key }) {
      this.updateLayout({
        ...this.layout,
        selectedKeys: [key],
      });
    },
    goToRoute(key, params = {}) {
      if (this.$route.name !== key)
        this.$router.push({ name: key, query: params });
    },
    goToLink(type) {
      const route = this.$router.resolve({
        name: "transaction-request",
        query: { type },
      });
      if (route.href !== this.$route.fullPath) this.$router.push(route.href);
    },
    handleRefreshBalance() {
      this.syncing = true;
      this.refreshBalance().finally(() => {
        this.syncing = false;
      });
    },
    openPromotionModal() {
      if (this.submitting) return;
      try {
        this.submitting = true;
        this.$confirm({
          title: this.labels.confirmPromotion.title,
          closable: true,
          class: "confirm-promotion-modal",
          content: (h) =>
            h(
              "div",
              {
                class: "confirm-promotion-content",
              },
              [
                h("p", this.labels.confirmPromotion.subtitle),
                h(
                  "a-form-item",
                  {
                    props: {
                      label: this.labels.confirmPromotion.code.label,
                      labelCol: { span: 24 },
                    },
                  },
                  [
                    h("a-input", {
                      props: {
                        placeholder:
                          this.labels.confirmPromotion.code.placeholder,
                        value: this.promotionCode,
                        allowClear: true,
                      },
                      on: {
                        input: (event) => {
                          this.promotionCode = event.target.value
                            ?.replace(/[^a-zA-Z0-9]/g, "")
                            ?.toUpperCase();
                        },
                      },
                    }),
                  ]
                ),
              ]
            ),
          okText: this.labels.confirmPromotion.confirmButtonText,
          icon: "close-circle",
          cancelButtonProps: { style: { display: "none" } },
          onOk: async () => {
            try {
              if (!this.promotionCode) {
                this.$message.error(this.labels.confirmPromotion.code.required);
                this.openPromotionModal();
                return;
              }
              await this.applyPromotion(this.promotionCode);
              this.$message.success(
                this.labels.confirmPromotion.appliedSuccess
              );
            } catch (error) {
              if (error?.response?.status === 400) {
                this.$notification["error"]({
                  message:
                    error.response?.data?.message || error.response?.message,
                  description: (h) => {
                    return h(
                      "ul",
                      error.response?.data?.additional_errors?.length
                        ? error.response?.data?.additional_errors?.map(
                            (error) => {
                              return h("li", error);
                            }
                          )
                        : h("li", error.response?.data?.message)
                    );
                  },
                });
              } else {
                if (error?.response?.status === 409) {
                  this.$message.warning(
                    error?.response?.data?.message ||
                      error?.response?.message ||
                      error.message ||
                      this.labels.confirmPromotion.appliedError
                  );
                } else {
                  this.$message.error(
                    error?.response?.data?.message ||
                      error?.response?.message ||
                      error.message ||
                      this.labels.confirmPromotion.appliedError
                  );
                }
              }
            }
          },
          onCancel: () => {
            this.promotionCode = "";
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.submitting = false;
      }
    },
  },
  computed: {
    ...mapGetters("layout", ["getLayoutDataStore"]),
    ...mapGetters("clientAuth", ["getLoggedUser"]),
    ...mapGetters("notifications", ["getUserNotifications"]),
    ...mapState("cart", ["plays"]),
    layout() {
      return this.getLayoutDataStore;
    },
    menus() {
      const { accessRoles = [] } = this.loggedUser || {};
      return menus(accessRoles).filter(
        (menu) => !menu?.hidden && !menu?.hiddenHeader
      );
    },
    breakPoint() {
      return this.layout.screenWidth <= 1199;
    },
    loggedUser() {
      return this.getLoggedUser;
    },
    fullName() {
      const { firstname = "", lastname = "" } = this.loggedUser;
      return `${firstname} ${lastname}`;
    },
    newNotifications() {
      return this.getUserNotifications.filter((item) => item.new);
    },
    notificationCount() {
      const count = this.newNotifications.length;
      if (count > 99) return "99+";
      return count;
    },
  },
  watch: {
    $route(to, from) {
      if (!_.isEqual(to, from)) {
        this.updateLayout({
          ...this.layout,
          selectedKeys: [to.name],
        });
      }
    },
    showNotifications(newVal) {
      if (newVal && this.newNotifications.length) {
        setTimeout(async () => {
          for (let notification of this.newNotifications) {
            await updateUserNotification({
              uuid: notification.uuid,
              new: false,
            });
          }
        }, 3000);
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.client-header {
  position: fixed;
  height: 74px !important;
  z-index: 3 !important;
  width: 100%;
  background-color: var(--navbar-bg);
  border-bottom: 1px solid var(--white-light);
  padding: 0px 5px;
  @media screen and (max-width: $screen-mobile) {
    height: 61px !important;
    justify-content: space-between;
    align-items: center;
    display: flex;
    transition: 0.5s ease-in-out;
  }
  .trigger {
    font-size: 26px;
    line-height: 78px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    height: 74px;
    color: var(--text);
    @media screen and (max-width: $screen-mobile) {
      padding: 0 5px;
    }
  }
  .client-menu {
    line-height: 74px;
    background: transparent;
    .ant-menu-item {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.875rem;
      color: var(--text);
      &-selected {
        color: var(--text-active);
        background-color: transparent !important;
      }
      &-active {
        color: var(--text-active);
        background-color: var(--navbar-hover-bg) !important;
      }
    }
  }
  .logo {
    width: 207px;
    margin: 13px 15px 0 0;
    float: left;
    transition: 0.5s ease-in-out;
    .img-logo {
      width: 100%;
      height: 38px;
      background-image: var(--banner-bg);
      background-position-y: center;
      background-position-x: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-origin: content-box;
      padding-bottom: 6px;
    }
    @media screen and (max-width: $screen-mobile) {
      margin: 0 15px 0 0;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.5s ease-in-out;
    }
  }
  .action-menu {
    position: absolute;
    right: 265px;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Roboto", sans-serif;
    @media screen and (max-width: $screen-mobile) {
      right: 10px;
    }
    &.mini {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
    }
    .btn-login {
      margin-right: 10px;
      border-radius: 50px !important;
      padding: 5px 20px;
      background-color: var(--btn-bg-primary);
      border-color: var(--btn-bc-primary);
      color: var(--btn-text-primary);
      font-weight: 500 !important;
      height: 36px;
    }
    .btn-register {
      color: var(--text);
      font-weight: 500 !important;
      &:hover {
        color: var(--text-active);
      }
    }
    .btn-cart {
      margin-right: 10px;
      border-radius: 0px !important;
      padding: 5px 24px;
      padding-left: 36px;
      background-color: transparent;
      color: var(--text-active);
      font-weight: 500 !important;
      height: 48px;
      &:hover {
        transition: 0.5s ease-in-out;
        border-color: var(--text-active);
      }
      .ant-badge {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 42px;
        margin: 0;
        padding: 0;
        line-height: 1;
        .ant-badge-count {
          right: 0;
          top: -2px;
        }
      }
      .pl-24 {
        margin-left: 24px;
      }
      &.mini {
        padding: 5px 20px;
        margin-right: 20px;
        height: 36px;
        border: none;
        font-weight: 400 !important;
        vertical-align: middle;
        .ant-badge {
          font-size: 30px;
        }
      }
    }
    .btn-balance {
      margin-right: 5px;
      border-radius: 0px !important;
      padding: 5px 24px;
      background-color: transparent;
      border: 1px solid var(--btn-bc-primary);
      color: var(--text-active);
      font-weight: 500 !important;
      height: 48px;
      user-select: none;
      &.mini {
        padding: 5px 5px 5px 12px;
      }
    }
    .refresh-balance {
      margin-right: 10px;
      padding: 0 8px;
      font-size: 18px;
      font-weight: 500;
      background-color: var(--btn-bg-primary);
      border: 1px solid var(--btn-bc-primary);
      color: var(--btn-text-primary);
      &:focus,
      &:active,
      &:hover {
        background-color: var(--btn-bg-primary);
        border: 1px solid var(--btn-bc-primary);
        color: var(--btn-text-primary);
      }
    }
    .refresh-balance-link {
      padding: 0 12px;
      font-size: 14px;
      font-weight: 600;
      color: var(--text);
      &:hover {
        color: var(--text-active);
      }
    }
    .user-info {
      font-family: "Roboto", sans-serif;
      color: var(--text);
      :hover {
        cursor: pointer;
      }
      &.mini {
        .ant-avatar-string {
          font-size: 16px !important;
        }
      }
    }
    .user-notifications {
      &-avatar {
        font-family: "Roboto", sans-serif;
        color: var(--text);
        position: relative;
        :hover {
          cursor: pointer;
        }
        &-count {
          text-align: center;
          position: absolute;
          top: 10px;
          right: 0;
          line-height: 20px;
          color: var(--white);
          &-text {
            background: var(--danger);
            color: var(--white);
            display: block;
            padding: 0 5px;
            height: 20px;
            min-width: 20px;
            border-radius: 50px;
          }
        }
      }
    }
    @media screen and (max-width: $screen-mobile) {
      .user {
        &-info {
          .ant-avatar {
            margin-right: 0;
            transition: 0.5s ease-in-out;
          }
        }
      }
    }
  }
  .btn-collapse {
    color: var(--text);
    border: 1px solid var(--btn-bc-primary);
    font-size: 22px;
    margin: 15px 24px 0 0;
    float: left;
    transition: 0.5s ease-in-out;
    padding-top: 5px;
    padding: 0 5px;
    @media screen and (max-width: $screen-mobile) {
      &.btn-sm-height-auto {
        height: auto;
        font-size: 22px;
        margin: 0 24px 0 0;
        .anticon {
          line-height: 1.4 !important;
          transition: 0.5s ease-in-out;
        }
        transition: 0.5s ease-in-out;
      }
    }
  }
  .btn-deposit {
    background-color: var(--btn-bg-primary);
    border: 1px solid var(--btn-bc-primary);
    color: var(--btn-text-primary);
    &:focus,
    &:active,
    &:hover {
      background-color: var(--btn-bg-primary);
      border: 1px solid var(--btn-bc-primary);
      color: var(--btn-text-primary);
    }
  }
  .btn-withdraw {
    background-color: var(--btn-bg-secondary);
    border: 1px solid var(--btn-bc-secondary);
    color: var(--btn-text-secondary);
    &:focus,
    &:active,
    &:hover {
      background-color: var(--btn-bg-secondary);
      border: 1px solid var(--btn-bc-secondary);
      color: var(--btn-text-secondary);
    }
  }
  .btn-transaction-group {
    margin-right: 10px;
    width: 52px !important;
    &.mini {
      width: auto !important;
      position: absolute;
      left: -140px;
      margin-right: 0;
      top: -5px;
    }
  }
  .btn-play-now {
    border-radius: 0px !important;
    background-color: var(--btn-bg-primary);
    border: 1px solid var(--btn-bc-primary);
    color: var(--btn-text-primary);
    font-weight: 500 !important;
    margin-right: 0;
    &:focus,
    &:active,
    &:hover {
      background-color: var(--btn-bg-primary);
      border: 1px solid var(--btn-bc-primary);
      color: var(--btn-text-primary);
    }
  }
  .text-right {
    text-align: right;
  }
}
.ant-popover-title {
  .content-user {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: normal;
    padding: 5px 15px;
    &-name {
      font-size: 1.2rem;
      font-weight: 600;
    }
    &-email {
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
}
.user-notifications-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: normal;
  padding: 5px 15px;
  font-size: 1.2rem;
  font-weight: 600;
}
.user-notifications-list-new {
  opacity: 0;
  margin-left: 8px;
  color: var(--text);
  font-size: 10px;
  background-color: var(--red);
  &-animate {
    opacity: 1;
    transition: 0.5s ease;
  }
}
.dropdown-btn {
  background-color: var(--btn-bg-secondary);
  border: 1px solid var(--btn-bc-secondary);
  color: var(--btn-text-secondary);
  font-size: 18px;
  padding: 0 8px;
  font-weight: 600;
  &:focus,
  &:active,
  &:hover {
    background-color: var(--btn-bg-secondary);
    border: 1px solid var(--btn-bc-secondary);
    color: var(--btn-text-secondary);
  }
}
.dropdown-menu {
  .ant-dropdown-menu-item {
    font-weight: 600;
    font-size: 16px;
  }
}
.confirm-promotion-modal {
  .ant-modal-content {
    border-radius: 0;
    background-color: var(--modal-bg);
    border: 1px solid var(--modal-shadow);
    .confirm-promotion-content,
    .ant-modal-confirm-title {
      color: var(--text) !important;
      .ant-form-item-label > label {
        color: var(--text) !important;
      }
    }
    .ant-modal-close-x {
      color: var(--text-active) !important;
    }
  }
  .ant-modal-confirm-btns {
    .ant-btn-primary {
      background-color: var(--btn-bg-secondary);
      border-color: var(--btn-bc-secondary);
      color: var(--btn-text-secondary);
      border-radius: 0;
      font-weight: 600;
      &:hover,
      &:focus,
      &:active {
        background-color: var(--btn-bg-secondary);
        border-color: var(--btn-bc-secondary);
        color: var(--btn-text-secondary);
      }
    }
  }
  .ant-modal-header {
    background-color: transparent;
    border-color: var(--hover);
    .title-text {
      color: var(--text);
      font-size: 18px;
      .anticon {
        color: var(--text-active);
      }
    }
  }
}
@media screen and (max-width: $screen-mobile) {
  .ant-layout-header {
    padding: 0 15px !important;
    transition: 0.5s ease-in-out;
  }
}
</style>
