import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient, restClientAll } from "../../utils/restClient";

const initialState = {
  raffles: [],
};

export default {
  namespaced: true,
  name: "raffles",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_RAFFLES](state, payload = []) {
      state.raffles = payload;
    },
  },
  actions: {
    async fetchRaffles({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.raffles.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_RAFFLES, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_RAFFLES, []);
          reject(error);
        }
      });
    },
    async fetchRaffle(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.raffles.single(payload),
            "GET"
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    async saveRaffle(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.raffles.base,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async confirmRaffle(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.raffles.confirm,
            "PATCH",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async patchRaffle(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.raffles.single(payload.id),
            "PATCH",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async deleteRaffle(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.raffles.singleByDate(payload),
            "DELETE"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async deleteRaffleById(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.raffles.single(payload),
            "DELETE"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchClientRaffles(_, payload = null) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientAll(
            payload?.lottery
              ? path.client.raffles.lottery(payload.date, payload.lottery)
              : path.client.raffles.base(payload.date),
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
