import _ from "lodash";
import { path } from "../../api";
import { restClient } from "../../utils/restClient";

const initialState = {};

export default {
  namespaced: true,
  name: "reports",
  state: _.cloneDeep(initialState),
  mutations: {},
  actions: {
    async fetchMonitoringReport() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.reports.monitoring,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
