<template>
  <div>
    <span
      class="backdrop"
      v-if="layout.breakPoint && !layout.collapsed"
      :style="{
        position: 'fixed',
        float: 'left',
        zIndex: 999,
        height: '100vh',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        backgroundColor: 'rgba(0,0,0,0.5)',
        paddingTop: 0,
        transition: 'all 0.3s ease-in-out',
      }"
      @click="() => layout.breakPoint && onCollapse(true)"
    />
    <a-layout-sider
      breakpoint="lg"
      :width="256"
      :collapsed="layout.collapsed"
      :trigger="null"
      :collapsible="true"
      :collapsed-width="layout.breakPoint ? '0px' : '88px'"
      @breakpoint="onBreakPoint"
      class="admin-sidebar-container"
      :style="{
        position: 'fixed',
        zIndex: 999,
        height: '100vh',
        left: 0,
        paddingTop: 0,
      }"
    >
      <div class="admin-sidebar-container-logo">
        <router-link
          :to="{ name: 'admin.dashboard' }"
          :style="{ display: 'flex', justifyContent: 'center' }"
        >
          <img
            alt="Logo"
            :src="layout.collapsed && !layout.breakPoint ? logo : banner"
            :style="{
              width: layout.collapsed
                ? '46px'
                : layout.breakPoint
                ? '190px'
                : '207px',
              display: 'inline-block',
              overflow: 'hidden',
            }"
            @click="() => layout.breakPoint && onCollapse(true)"
          />
        </router-link>
        <a-button
          v-if="layout.breakPoint"
          class="btn-close"
          @click="() => onCollapse(true)"
          shape="circle"
          icon="close"
        />
      </div>
      <perfect-scrollbar>
        <div
          class="scroll-bar"
          :style="{
            maxHeight: 'CALC(100vh - 88.94px)',
            paddingBottom: 25,
          }"
        >
          <a-menu
            v-if="authenticated"
            theme="dark"
            mode="inline"
            :openKeys="layout.openKeys.filter((key) => !!key) || []"
            @openChange="onOpenChange"
            @select="onSelectMenuChange"
            :selectedKeys="layout.selectedKeys || []"
            :style="{
              borderRight: 0,
              height: 'calc(100% - 16px)',
              background: 'transparent',
            }"
            :subMenuCloseDelay="0.1"
            :subMenuOpenDelay="0.1"
          >
            <template v-for="menu in menus">
              <a-sub-menu
                v-if="menu.children && menu.children.length"
                :key="menu.key"
              >
                <span slot="title">
                  <component
                    v-if="menu.icon"
                    :is="menu.isFontAwesome ? 'font-awesome-icon' : 'a-icon'"
                    :icon="menu.icon"
                    :type="menu.icon"
                    :class="menu.isFontAwesome && 'anticon'"
                  />
                  <span>{{ menu.title }}</span>
                </span>
                <a-menu-item
                  v-for="submenu in menu.children"
                  :key="submenu.key"
                  :to="{ name: submenu.key }"
                  @click="() => layout.breakPoint && onCollapse(true)"
                >
                  <component
                    v-if="submenu.icon"
                    :is="submenu.isFontAwesome ? 'font-awesome-icon' : 'a-icon'"
                    :icon="submenu.icon"
                    :type="submenu.icon"
                    :class="submenu.isFontAwesome && 'anticon'"
                  />
                  <span>{{ submenu.title }}</span>
                  <router-link v-if="submenu.key" :to="{ name: submenu.key }" />
                </a-menu-item>
              </a-sub-menu>

              <a-menu-item
                v-else
                :key="menu.key"
                @click="() => layout.breakPoint && onCollapse(true)"
                :to="{ name: menu.key }"
              >
                <component
                  v-if="menu.icon"
                  :is="menu.isFontAwesome ? 'font-awesome-icon' : 'a-icon'"
                  :icon="menu.icon"
                  :type="menu.icon"
                  :class="menu.isFontAwesome && 'anticon'"
                />
                <span>{{ menu.title }}</span>
                <router-link v-if="menu.key" :to="{ name: menu.key }" />
              </a-menu-item>
            </template>
          </a-menu>
        </div>
      </perfect-scrollbar>
    </a-layout-sider>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import logo from "@/assets/images/admin/logo.png";
import banner from "@/assets/images/admin/banner.png";
import { menus } from "@/router/menus.js";
export default {
  name: "SideBar",
  data() {
    return {
      logo,
      banner,
    };
  },
  methods: {
    ...mapActions("layout", ["updateLayout"]),
    onOpenChange(openKeys) {
      const item = openKeys.pop();
      this.updateLayout({
        ...this.layout,
        openKeys: item ? [item] : [],
      });
    },
    onSelectMenuChange({ key }) {
      this.updateLayout({
        ...this.layout,
        selectedKeys: [key],
      });
    },
    onBreakPoint(broken) {
      this.updateLayout({
        ...this.layout,
        breakPoint: broken,
        collapsed: broken,
      });
    },
    onCollapse(collapsed = true) {
      this.updateLayout({
        ...this.layout,
        collapsed: collapsed,
      });
    },
  },
  created() {
    this.updateLayout({
      ...this.layout,
      selectedKeys: [this.$route.name],
    });
  },
  computed: {
    ...mapGetters("layout", ["getLayoutDataStore"]),
    ...mapState("adminAuth", ["user", "authenticated"]),
    layout() {
      return this.getLayoutDataStore;
    },
    menus() {
      const { accessRoles = [] } = this.user || {};
      return menus(accessRoles);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/variable";
#admin-layout {
  .admin-sidebar-container {
    background: url(../../../assets/images/admin/sidebar-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 999;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8 !important;
      background-color: color(secondary) !important;
      z-index: -10;
    }
    &-logo {
      position: relative;
      overflow: hidden;
      height: 61px;
      cursor: pointer;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding: 10px 10px;
      @media screen and (max-width: $screen-mobile) {
        justify-content: space-between;
      }
    }
    .btn-close {
      color: whitesmoke !important;
      position: absolute !important;
      z-index: 1 !important;
      top: 50% !important;
      right: 15px !important;
      background: transparent !important;
      border: none !important;
      transform: translateY(-50%) !important;
      transition: 0.5s ease-in-out;
      line-height: 0.9 !important;
      &:hover {
        background: rgba(152, 152, 152, 0.3) !important;
        transition: 0.5s ease-in-out;
      }
    }
  }
}
</style>
<style lang="scss">
.admin-sidebar-container {
  .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: var(--red) !important;
  }
  .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ant-menu.ant-menu-dark .ant-menu-item-active,
  .ant-menu-dark .ant-menu-inline.ant-menu-sub,
  .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}
</style>
