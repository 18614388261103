import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient } from "../../utils/restClient";

const initialState = {
  blocks: [],
  block: null,
};

export default {
  namespaced: true,
  name: "blocks",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_BLOCKS](state, payload = []) {
      state.blocks = payload;
    },
    [actions.GET_BLOCK](state, payload = null) {
      state.block = payload;
    },
    [actions.CREATE_BLOCK](state, payload = null) {
      state.block = payload;
    },
    [actions.UPDATE_BLOCK](state, payload = null) {
      state.block = payload;
    },
    [actions.RESET_BLOCK_FORM](state) {
      state.block = null;
    },
  },
  actions: {
    async fetchBlocks({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.blocks.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_BLOCKS, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_BLOCKS, []);
          reject(error);
        }
      });
    },
    async fetchBlock({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.blocks.single(payload),
            "GET"
          );
          commit(actions.GET_BLOCK, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_BLOCK, null);
          reject(error);
        }
      });
    },
    async createBlock({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.blocks.base,
            "POST",
            payload
          );
          commit(actions.CREATE_BLOCK, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async updateBlock({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.blocks.single(payload.id),
            "PUT",
            payload
          );
          commit(actions.UPDATE_BLOCK, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async deleteBlock(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.blocks.single(payload),
            "DELETE"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    resetForm({ commit }) {
      commit(actions.RESET_BLOCK_FORM);
    },
  },
};
