<template>
  <a-layout id="app-layout">
    <Teleport to="body" v-if="htmlHeader">
      <span style="display: none" v-html="htmlHeader" />
    </Teleport>
    <SideBar />
    <a-layout
      :style="{
        marginLeft: sizeMargin,
        minHeight: '100vh',
        transition: 'ease 0.2s',
      }"
    >
      <ClientHeader />
      <a-layout-content class="content-wrapper">
        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
          <router-view />
        </transition>
      </a-layout-content>

      <a-layout-footer class="client-footer">
        <div class="footer-content">
          <span :style="{ marginRight: '10px', fontWeight: 600 }">{{
            labels.allRightsReserved(labels.appName)
          }}</span>
          <!-- social networks -->
          <div class="social-network">
            <a v-if="getUrl('facebook')" :href="getUrl('facebook')">
              <font-awesome-icon
                :icon="['fab', 'facebook-f']"
                :class="'anticon'"
              />
            </a>
            <a v-if="getUrl('twitter')" :href="getUrl('twitter')">
              <font-awesome-icon
                :icon="['fab', 'twitter']"
                :class="'anticon'"
              />
            </a>
            <a v-if="getUrl('linkedin')" :href="getUrl('linkedin')">
              <font-awesome-icon
                :icon="['fab', 'linkedin-in']"
                :class="'anticon'"
              />
            </a>
            <a v-if="getUrl('instagram')" :href="getUrl('instagram')">
              <font-awesome-icon
                :icon="['fab', 'instagram']"
                :class="'anticon'"
              />
            </a>
            <a v-if="getUrl('youtube')" :href="getUrl('youtube')">
              <font-awesome-icon
                :icon="['fab', 'youtube']"
                :class="'anticon'"
              />
            </a>
          </div>
        </div>
        <a-divider class="custom-separator" />
        <div class="footer-content-link">
          <router-link class="footer-link" :to="{ name: 'privacy-policies' }"
            >Políticas de privacidad</router-link
          >
          <router-link
            class="footer-link"
            :to="{ name: 'terms-and-conditions' }"
            >Términos y condiciones</router-link
          >
        </div>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import SideBar from "../components/layout/client/SideBar.vue";
import ClientHeader from "../components/layout/client/ClientHeader.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import labels from "@/utils/labels";
import Teleport from "vue2-teleport";
export default {
  components: { ClientHeader, SideBar, Teleport },
  name: "AppLayout",
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      await Promise.all([
        vm.getSocialNetworksConfig(),
        vm.handleGetPrivacyPoliciesTermsConditions(),
        vm.fetchHTMLHeader(),
      ]);
    });
  },
  data() {
    return {
      labels: labels,
    };
  },
  methods: {
    ...mapActions("cart", ["getCartPlays"]),
    ...mapActions("systemConfig", [
      "fetchSocialNetworksConfig",
      "getPrivacyPoliciesTermsConditions",
      "fetchHTMLHeader",
    ]),
    async getSocialNetworksConfig() {
      try {
        await this.fetchSocialNetworksConfig();
      } catch (error) {
        console.error(error);
      }
    },
    async handleGetPrivacyPoliciesTermsConditions() {
      try {
        await this.getPrivacyPoliciesTermsConditions();
      } catch (error) {
        console.error(error);
      }
    },
    getUrl(name) {
      const { socialNetworks = [] } = this;
      const socialNetwork = socialNetworks.find(
        (item) => !!item?.label?.toLowerCase()?.includes(name)
      );
      if (!socialNetwork) {
        return null;
      }
      return socialNetwork.value;
    },
  },
  computed: {
    ...mapGetters("layout", ["getLayoutDataStore"]),
    ...mapState("clientAuth", ["authenticated"]),
    ...mapState("systemConfig", ["socialNetworks"]),
    ...mapGetters({
      htmlHeader: "systemConfig/getHTMLHeader",
    }),
    layout() {
      return this.getLayoutDataStore;
    },
    breakPoint() {
      return this.layout.screenWidth <= 1199;
    },
    sizeMargin() {
      return this.layout.breakPoint
        ? "0px"
        : this.layout.collapsed
        ? "88px"
        : "256px";
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variable";
#app-layout {
  flex-direction: column;
  .content-wrapper {
    margin-top: 74px;
    min-height: calc(100vh - 84px - 74px);
    display: flex;
    width: 100% !important;
    height: auto;
    background: var(--background);
    color: var(--text);
    padding-bottom: 60px;
    @media screen and (max-width: $screen-mobile) {
      min-height: calc(100vh - 84px - 60px);
      padding-bottom: 50px;
      margin-top: 60px;
      transition: 1s ease-in-out;
    }
  }
  .client-footer {
    background-color: var(--footer-bg);
    color: var(--text);
    font-family: "Roboto", sans-serif;
    display: flex;
    width: 100% !important;
    flex-direction: column;
    position: relative;
    height: auto;
    @media screen and (max-width: $screen-mobile) {
      padding: 24px 20px;
      transition: 0.5s ease-in-out;
    }
    .footer-content {
      display: flex !important;
      justify-content: space-between !important;
      width: 100% !important;
      align-items: center;
      transition: 0.5s ease-in-out;
      .social-network {
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        a {
          color: color(--white);
          width: 36px !important;
          height: 36px !important;
          border: 1px solid var(--text);
          text-decoration: none;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          &:hover {
            transition: ease-in-out 0.3s;
            background-color: var(--hover);
          }
          .anticon {
            font-size: 20px !important;
          }
        }
      }
      @media screen and (max-width: 600px) {
        flex-direction: column-reverse;
        gap: 15px;
        text-align: center !important;
        transition: 0.5s ease-in-out;
      }
      &-link {
        .footer-link {
          color: var(--link);
          text-decoration: underline;
          &:not(:last-child) {
            padding-right: 5px;
            margin-right: 5px;
            border-right: 1px solid var(--text-muted);
          }
        }
      }
    }
    .custom-separator {
      background: var(--text-muted);
    }
  }
}
</style>
