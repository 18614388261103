var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-layout',{attrs:{"id":"admin-layout"}},[_c('side-bar'),_c('a-layout',{style:({
      marginLeft: _vm.sizeMargin,
      minHeight: '100vh',
      transition: 'ease 0.2s',
    })},[_c('admin-header'),_c('a-layout-content',{style:({
        margin: '2px 0',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
      })},[_c('transition',{attrs:{"mode":"out-in","enter-active-class":"animate__animated animate__fadeIn animate__faster","leave-active-class":"animate__animated animate__fadeOut animate__faster"}},[_c('router-view')],1)],1),_c('a-layout-footer',{staticClass:"admin-footer"},[_c('span',{style:({ marginRight: '10px', fontWeight: 600 })},[_vm._v(_vm._s(_vm.labels.allRightsReserved(_vm.labels.appName)))]),_c('a-tag',{style:({ padding: '0 10px', fontWeight: 600, marginRight: 0 }),attrs:{"color":"#108ee9"}},[_vm._v("v"+_vm._s(_vm.appVersion))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }