import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import { firebaseEnabled, firebaseConfig, vapidKey } from "./config.js";
import localStorageService from "../utils/localStorage";
import { apiLsClientVariable } from "../utils/utils";
import store from "../store";

let messaging = null;
let isFirebaseEnabled = firebaseEnabled;

try {
  if (window.Notification.permission === "denied") {
    isFirebaseEnabled = false;
  } else if (window.Notification.permission !== "granted") {
    window.Notification.requestPermission().then((permission) => {
      if (permission !== "granted") isFirebaseEnabled = false;
    });
  }

  if (isFirebaseEnabled) {
    isSupported().then((firebaseSupported) => {
      if (!firebaseSupported) {
        isFirebaseEnabled = false;
        console.error("Firebase is not supported.");
        return;
      }
    });

    initializeApp(firebaseConfig);
    messaging = getMessaging();

    onMessage(messaging, () => {
      store.dispatch("notifications/refreshUserNotifications");
    });

    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible") {
        store.dispatch("notifications/refreshUserNotifications");
      }
    });
  }
} catch (e) {
  isFirebaseEnabled = false;
  console.error("Firebase is not enabled.");
}

//----------------

const parseUserNotifications = (notifications) => {
  return notifications.map((notification) => {
    // TODO: Remove unnecessary/sensitive fields ?
    let data = notification.data;

    try {
      if (typeof data?.body !== "string" && !!data.body.message) {
        data = { ...data, data: data.body, body: data.title, title: "RD" }; // TODO: Remove this when all notifications are updated
      }
    } catch (e) {
      //console.error(e);
    }

    return {
      ...notification,
      ...data,
    };
  });
};

const storeFCMToken = async (token) => {
  if (!token?.token) removeFCMToken();
  token = window.btoa(JSON.stringify(token));
  localStorageService.set(apiLsClientVariable + "-FCM", token);
  return token;
};

const getFCMToken = async () => {
  if (!isFirebaseEnabled || !messaging) return;
  try {
    const token = await getToken(messaging, { vapidKey });
    return token;
  } catch (e) {
    console.error("Error getting FCM token: ", e);
  }
  return null;
};

const removeFCMToken = () => {
  localStorageService.remove(apiLsClientVariable + "-FCM");
  return null;
};

const getUserNuuid = (nuuid = null) => {
  const key = apiLsClientVariable + "-NUUID";
  if (nuuid) {
    localStorageService.set(key, window.btoa(nuuid));
  } else if (localStorageService.get(key)) {
    nuuid = window.atob(localStorageService.get(key));
  }
  return nuuid;
};

const registerToken = async (nuuid = null) => {
  if (!isFirebaseEnabled || !messaging) return false;
  if (nuuid) getUserNuuid(nuuid);
  const token = await getFCMToken();
  if (token && nuuid) {
    store.dispatch("notifications/registerFCMToken", { token });
  }
};

const refreshUserNotifications = () => {
  if (!isFirebaseEnabled || !messaging) return false;
  store.dispatch("notifications/refreshUserNotifications");
};

const updateUserNotification = async (notification) => {
  if (!isFirebaseEnabled || !messaging) return false;
  store.dispatch("notifications/updateUserNotifications", notification);
};

const deleteUserNotification = async (notification) => {
  if (!isFirebaseEnabled || !messaging) return false;
  store.dispatch("notifications/deleteUserNotifications", notification);
};

getFCMToken();

export {
  getFCMToken,
  storeFCMToken,
  removeFCMToken,
  getUserNuuid,
  registerToken,
  refreshUserNotifications,
  updateUserNotification,
  deleteUserNotification,
  parseUserNotifications,
};
