import _ from "lodash";
import { path } from "../../api";
import * as actions from "../../utils/actions";
import { restClient } from "../../utils/restClient";

const initialState = {
  lotteries: [],
  lottery: null,
  schedules: [],
  schedule: null,
};

export default {
  namespaced: true,
  name: "lotteries",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_LOTTERY](state, payload = null) {
      state.lottery = payload;
    },
    [actions.GET_LOTTERIES_SCHEDULE](state, payload = null) {
      state.schedule = payload;
    },
    [actions.GET_LOTTERIES](state, payload = []) {
      state.lotteries = payload;
    },
    [actions.GET_LOTTERIES_SCHEDULES](state, payload = []) {
      state.schedules = payload;
    },
    [actions.CREATE_LOTTERY](state, payload = null) {
      state.lottery = payload;
    },
    [actions.UPDATE_LOTTERY](state, payload = null) {
      state.lottery = payload;
    },
    [actions.CREATE_LOTTERIES_SCHEDULE](state, payload = null) {
      state.schedule = payload;
    },
    [actions.UPDATE_LOTTERIES_SCHEDULE](state, payload = null) {
      state.schedule = payload;
    },
    [actions.DELETE_LOTTERY](state, payload = null) {
      state.lottery = payload;
    },
    [actions.DELETE_LOTTERIES_SCHEDULE](state, payload = null) {
      state.schedule = payload;
    },
    [actions.RESET_LOTTERY_FORM](state) {
      state.lottery = null;
    },
    [actions.RESET_LOTTERIES_SCHEDULE_FORM](state) {
      state.schedule = null;
    },
  },
  actions: {
    async fetchLotteries({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.lotteries.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_LOTTERIES, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_LOTTERIES, []);
          reject(error);
        }
      });
    },
    async fetchSchedules({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.lotteryClosingSchedule.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_LOTTERIES_SCHEDULES, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_LOTTERIES_SCHEDULES, []);
          reject(error);
        }
      });
    },
    async fetchLottery({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.lotteries.single(payload),
            "GET"
          );
          commit(actions.GET_LOTTERY, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_LOTTERY);
          reject(error);
        }
      });
    },
    async fetchSchedule({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await restClient(
            path.admin.lotteryClosingSchedule.single(payload),
            "GET"
          );
          commit(actions.GET_LOTTERIES_SCHEDULE, data);
          resolve(data);
        } catch (error) {
          commit(actions.GET_LOTTERIES_SCHEDULE);
          reject(error);
        }
      });
    },
    async createLottery({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.lotteries.base,
            "POST",
            payload
          );
          commit(actions.CREATE_LOTTERY, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async updateLottery({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.lotteries.single(payload.id),
            "PUT",
            payload
          );
          commit(actions.UPDATE_LOTTERY, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async createSchedule({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.lotteryClosingSchedule.base,
            "POST",
            payload
          );
          commit(actions.CREATE_LOTTERIES_SCHEDULE, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async updateSchedule({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.lotteryClosingSchedule.single(payload.id),
            "PUT",
            payload
          );
          commit(actions.UPDATE_LOTTERIES_SCHEDULE, response?.data);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async deleteLottery({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.lotteries.single(payload),
            "DELETE"
          );
          commit(actions.DELETE_LOTTERY);
          resolve(response);
        } catch (error) {
          commit(actions.DELETE_LOTTERY);
          reject(error);
        }
      });
    },
    async deleteSchedule({ commit }, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.lotteryClosingSchedule.single(payload),
            "DELETE"
          );
          commit(actions.DELETE_LOTTERIES_SCHEDULE);
          resolve(response);
        } catch (error) {
          commit(actions.DELETE_LOTTERIES_SCHEDULE);
          reject(error);
        }
      });
    },
    resetForm({ commit }) {
      commit(actions.RESET_LOTTERY_FORM);
    },
    resetScheduleForm({ commit }) {
      commit(actions.RESET_LOTTERIES_SCHEDULE_FORM);
    },
  },
  getters: {
    getStrLotteries: (state) => {
      return state.lotteries.length > 0
        ? state.lotteries.map((lottery) => lottery.name).join(", ")
        : "No se han seleccionado loterías";
    },
  },
};
