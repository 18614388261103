<template>
  <a-layout id="admin-layout">
    <side-bar />
    <a-layout
      :style="{
        marginLeft: sizeMargin,
        minHeight: '100vh',
        transition: 'ease 0.2s',
      }"
    >
      <admin-header />
      <a-layout-content
        :style="{
          margin: '2px 0',
          padding: '24px',
          background: '#fff',
          minHeight: '280px',
        }"
      >
        <transition
          mode="out-in"
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
          <router-view />
        </transition>
      </a-layout-content>
      <a-layout-footer class="admin-footer">
        <span :style="{ marginRight: '10px', fontWeight: 600 }">{{
          labels.allRightsReserved(labels.appName)
        }}</span>
        <a-tag
          color="#108ee9"
          :style="{ padding: '0 10px', fontWeight: 600, marginRight: 0 }"
          >v{{ appVersion }}</a-tag
        >
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import SideBar from "../components/layout/admin/SideBar.vue";
import AdminHeader from "../components/layout/admin/AdminHeader.vue";
import { mapState } from "vuex";
import pkg from "@/../package.json";
import labels from "@/utils/labels";
export default {
  components: { SideBar, AdminHeader },
  data() {
    return {
      labels: labels,
      appVersion: pkg.version,
    };
  },
  name: "AdminLayout",
  computed: {
    ...mapState("layout", ["breakPoint", "collapsed"]),
    sizeMargin() {
      return this.breakPoint ? "0px" : this.collapsed ? "88px" : "256px";
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variable";
#admin-layout {
  min-height: 100vh;
  .animate__animated.animate__fadeIn,
  .animate__animated.animate__fadeOut {
    --animate-duration: 0.4s !important;
  }
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: color(link);
    }
  }
  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }
  .hover:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .admin-footer {
    padding: 5px 20px;
    text-align: right;
  }
}
</style>
