<template>
  <div :class="`landing-${type}`">
    <div v-if="type === 'roller'" class="loading">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
    <div class="loading" v-else-if="type === 'ring'">
      <div />
      <div />
      <div />
    </div>
    <div class="loading" v-else>
      <div />
      <div />
    </div>
  </div>
</template>
<script>
export default {
  name: "LandingRing",
  props: {
    type: {
      type: String,
      options: ["ring", "roller", "ripple"],
      default: "ring",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.landing-roller {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--background);
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 80px;
    div {
      animation: loading-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
      &:after {
        content: " ";
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: var(--text-active);
        margin: -4px 0 0 -4px;
      }
      &:nth-child(1) {
        animation-delay: -0.036s;
        &:after {
          top: 63px;
          left: 63px;
        }
      }
      &:nth-child(2) {
        animation-delay: -0.072s;
        &:after {
          top: 68px;
          left: 56px;
        }
      }
      &:nth-child(3) {
        animation-delay: -0.108s;
        &:after {
          top: 71px;
          left: 48px;
        }
      }
      &:nth-child(4) {
        animation-delay: -0.144s;
        &:after {
          top: 72px;
          left: 40px;
        }
      }
      &:nth-child(5) {
        animation-delay: -0.18s;
        &:after {
          top: 71px;
          left: 32px;
        }
      }
      &:nth-child(6) {
        animation-delay: -0.216s;
        &:after {
          top: 68px;
          left: 24px;
        }
      }
      &:nth-child(7) {
        animation-delay: -0.252s;
        &:after {
          top: 63px;
          left: 17px;
        }
      }
      &:nth-child(8) {
        animation-delay: -0.288s;
        &:after {
          top: 56px;
          left: 12px;
        }
      }
    }
  }
}

.landing-ring {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  .loading {
    position: relative;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid var(--text-active);
      border-radius: 50%;
      animation: landing-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: var(--text-active) transparent transparent transparent;
      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
}

.landing-ripple {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  .loading {
    position: relative;
    div {
      position: absolute;
      border: 4px solid var(--text-active);
      opacity: 1;
      border-radius: 50%;
      animation: landing-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
}
@keyframes landing-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@keyframes landing-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
